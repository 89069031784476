
import { useRoute } from 'vue-router'
import {
  computed, defineComponent,
  ref,
  shallowRef,
} from 'vue'
import Page from '@/components/Page.vue'
import Box from '@/components/Box.vue'
import ProAccountFormSettings from '@/components/Forms/ProAccountFormSettings.vue'
import ProAccountFormBillingInfo from '@/components/Forms/ProAccountFormBillingInfo.vue'
import ProAccountFormLaboratories from '@/components/Forms/ProAccountFormLaboratories.vue'
import ProAccountFormBase from '@/components/Forms/ProAccountFormBase.vue'
import ProAccountFormPrices from '@/components/Forms/ProAccountFormPrices.vue'
import ProAccountFormHandOverData from '@/components/Forms/ProAccountFormHandOverData.vue'
import { useStore } from 'vuex'
import Stat from '@/components/Stat.vue'
import { IAccount } from '@/types'
import PageTop from '@/components/PageTop.vue'
import Button from '@/components/Form/Button.vue'
import { debounce } from 'lodash-es'
import { mixWB } from '@/globals/javascript/utils/mixins'
import router from '@/router'

type PartialAccount = Omit<IAccount, 'numberID'|'nextQRLabelNumber'|'createdDate'|'nextScreeningNumber'>

export default defineComponent({
  components: {
    Page,
    Box,
    Stat,
    PageTop,
    Button,
    ProAccountFormBase,
    ProAccountFormLaboratories,
    ProAccountFormPrices,
    ProAccountFormSettings,
    ProAccountFormBillingInfo,
    ProAccountFormHandOverData,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const { isAdmin } = store.getters
    const isLoading = ref(false)
    const isNew = computed(() => route.name === 'ProAccountNew')
    const finishedSteps = ref<string[]>([])
    const currentDate = new Date().getTime()
    const selectedPageID = ref('base-data')
    const existingAccount = computed<IAccount | undefined>(
      () => route.params.accountID && store.getters.getAccountByID(route.params.accountID),
    )

    // Clean account data object for creating a new account
    const logs = [
      {
        changes: [],
        timestamp: currentDate,
        userID: store.getters.currentUser.id,
      },
    ]
    const newAccountData = ref<PartialAccount>(
      {
        id: '',
        name: '',
        baseCountry: 'DK',
        address: '',
        postalCode: '',
        city: '',
        contactPerson: {
          name: '',
          phoneNumber: '',
          email: '',
        },
        primaryLabID: 'euroFins',
        analysisDeliveryTimeOptionID: 'ADO-6',
        euroFinsNumber: '',
        hojvangNumber: '',
        analysisReportEmail: '',
        companyInitials: '',
        isActive: true,
        useMandatoryManualCaseNumbers: false,
        useSamplingID: false,
        canUseSampler: false,
        canUseCustomAddress: false,
        hidePrices: false,
        allowMilvaEuroFins: false,
        billPerRequisition: false,
        autoOrderCPFromCPScreening: false,
        autoOrderPAHFromCH: false,
        isInvoicePaymentSelected: false,
        isInvoicePaymentAllowed: false,
        images: {
          mainLogo: '',
          emailLogo: '',
        },
        allowedProjectTypeIDs: [],
        autoSelectedProjectOptionIDs: [],
        prices: {
          other: {
            projectFee: {
              type: 'price',
              value: 0,
              logs,
              isActive: false,
            },
          },
          matAnalyses: {
            'ST-1': {
              type: 'price',
              value: 0,
              logs,
              isActive: false,
            },
            'ST-2': {
              type: 'price',
              value: 0,
              logs,
              isActive: false,
            },
            'ST-3': {
              type: 'price',
              value: 0,
              logs,
              isActive: false,
            },
            'ST-4': {
              type: 'price',
              value: 0,
              logs,
              isActive: false,
            },
            'ST-5': {
              type: 'price',
              value: 0,
              logs,
              isActive: false,
            },
            'ST-6': {
              type: 'price',
              value: 0,
              logs,
              isActive: false,
            },
            'ST-7': {
              type: 'price',
              value: 0,
              logs,
              isActive: false,
            },
            'ST-8': {
              type: 'price',
              value: 0,
              logs,
              isActive: false,
            },
            'ST-9': {
              type: 'price',
              value: 0,
              logs,
              isActive: false,
            },
          },
          analysisDeliveryTime: {
            'ADO-1': {
              type: 'multiplier',
              value: 0,
              logs,
              isActive: false,
            },
            'ADO-2': {
              type: 'multiplier',
              value: 0,
              logs,
              isActive: false,
            },
            'ADO-3': {
              type: 'multiplier',
              value: 0,
              logs,
              isActive: false,
            },
            'ADO-4': {
              type: 'multiplier',
              value: 0,
              logs,
              isActive: false,
            },
            'ADO-5': {
              type: 'multiplier',
              value: 0,
              logs,
              isActive: false,
            },
            'ADO-6': {
              type: 'multiplier',
              value: 0,
              logs,
              isActive: false,
            },
          },
          sampleHandOver: {
            'drop-off-point': {
              type: 'price',
              value: 0,
              logs,
              isActive: false,
            },
            'pick-up-point': {
              type: 'price',
              value: 0,
              logs,
              isActive: false,
            },
            'custom-address': {
              type: 'price',
              value: 0,
              logs,
              isActive: false,
            },
          },
        },
        billingInfo: {
          customerType: 'business',
          countryCode: 'DK',
          fullName: '',
          email: '',
          phone: '',
          address: '',
          postalCode: '',
          city: '',
          company: '',
          vatNumber: '',
          att: '',
        },
        pickUpPoints: [],
      },
    )

    const accountToUse = computed<PartialAccount | IAccount | undefined>(
      () => {
        if (isNew.value) {
          return newAccountData.value
        }
        return Object.assign(newAccountData.value, existingAccount.value)
      },
    )

    const updateNewAccountDataDebouced = debounce((accountData:PartialAccount |IAccount) => {
      newAccountData.value = { ...newAccountData.value, ...accountData }
    }, 100)

    const updateAccountDebouced = debounce((accountData:PartialAccount |IAccount) => {
      store.dispatch('updateAccount', { data: accountData, id: existingAccount.value?.id })
    }, 100)

    const steps = ref([{
      title: mixWB('W: Om virksomheden'),
      id: 'base-data',
      formComponent: shallowRef(ProAccountFormBase),
      isFinished: false,
      updateOnFieldInput: false,
    },
    {
      title: mixWB('W: Indstillinger'),
      id: 'settings',
      formComponent: shallowRef(ProAccountFormSettings),
      isFinished: false,
      updateOnFieldInput: false,
    },
    {
      title: mixWB('W: Fakturering (Sampler)'),
      id: 'billingInfo',
      formComponent: shallowRef(ProAccountFormBillingInfo),
      isFinished: false,
      updateOnFieldInput: false,
    },
    {
      title: mixWB('W: Overlevering (Sampler)'),
      id: 'handOverData',
      formComponent: shallowRef(ProAccountFormHandOverData),
      isFinished: false,
      updateOnFieldInput: true,
    },
    {
      title: mixWB('W: Laboratorier (Mapper)'),
      id: 'laboratories',
      formComponent: shallowRef(ProAccountFormLaboratories),
      isFinished: false,
      updateOnFieldInput: false,
    },
    {
      title: mixWB('W: Priser'),
      id: 'prices',
      formComponent: shallowRef(ProAccountFormPrices),
      isFinished: false,
    }])

    const buttonLabel = computed(() => {
      if (steps.value[steps.value.length - 1]?.id === selectedPageID.value && isNew.value) {
        return mixWB('W: Opret konto')
      }
      if (isNew.value) {
        return mixWB('W: Videre')
      }
      return mixWB('W: Opdater')
    })

    function goToStep(stepID: typeof steps['value'][number]['id']) {
      selectedPageID.value = stepID
    }

    function goToPrevStep() {
      const currentIndex = steps.value.findIndex((s) => s.id === selectedPageID.value)
      const nextStepObject = steps.value[currentIndex - 1]
      if (!nextStepObject) {
        return
      }
      goToStep(nextStepObject.id)
    }

    function goToLastActiveStep() {
      const nextStepObject = steps.value.find((s) => s.isFinished === false)
      if (!nextStepObject) {
        return
      }
      goToStep(nextStepObject.id)
    }

    function onSubmit(step: typeof steps['value'][number], updatedData: PartialAccount | IAccount) {
      const isLastStep = steps.value[steps.value.length - 1]?.id === step.id
      // If new account and last step - create account
      if (isNew.value && isLastStep) {
        isLoading.value = true
        store.dispatch('createAccount', { data: newAccountData.value })
          .then((accountID: string) => {
            router.push({ name: 'ProAccount', params: { accountID } })
            goToStep(steps.value[0].id)
            isLoading.value = false
          })
      }
      // If new - update newAccountData
      if (isNew.value && !isLastStep) {
        step.isFinished = true
        goToLastActiveStep()
      }
      // If existing - save updates
      if (!isNew.value && existingAccount.value) {
        isLoading.value = true

        store.dispatch('updateAccount', { data: updatedData, id: existingAccount.value.id })
          .then(() => {
            setTimeout(() => {
              isLoading.value = false
            }, 1000)
          })
      }
    }

    function onFieldInput(step: typeof steps['value'][number], accountData: PartialAccount | IAccount) {
      if (isNew.value) {
        step.isFinished = false
        updateNewAccountDataDebouced(accountData)
        return
      }
      if (!isNew.value && step.updateOnFieldInput) {
        updateAccountDebouced(accountData)
      }
    }

    function isStepActive(stepID: typeof steps['value'][number]['id']) {
      if (!isNew.value) {
        return true
      }

      const stepIndex = steps.value.findIndex((s) => s.id === stepID)

      if (stepIndex === 0) {
        return true
      }

      return steps.value.every((s, i) => {
        if (i < stepIndex && !s.isFinished) {
          return false
        }

        return true
      })
    }

    return {
      isAdmin,
      onFieldInput,
      finishedSteps,
      isNew,
      selectedPageID,
      existingAccount,
      accountToUse,
      onSubmit,
      isLoading,
      goToLastActiveStep,
      goToPrevStep,
      goToStep,
      steps,
      isStepActive,
      buttonLabel,
    }
  },
})

