import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toggle = _resolveComponent("Toggle")
  const _component_Select = _resolveComponent("Select")
  const _component_InputField = _resolveComponent("InputField")

  return (_ctx.accountDataCopy)
    ? (_openBlock(), _createBlock("form", {
        key: 0,
        onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"])),
        ref: "form"
      }, [
        _createVNode(_component_Toggle, {
          name: "allowMilvaEuroFins",
          label: "Kan bruge EuroFins gennem Milva",
          data: _ctx.accountDataCopy.allowMilvaEuroFins,
          "onUpdate:data": [
            _cache[1] || (_cache[1] = ($event: any) => (_ctx.accountDataCopy.allowMilvaEuroFins = $event)),
            _ctx.onAllowMilvaEuroFinsUpdate
          ]
        }, null, 8, ["data", "onUpdate:data"]),
        (_ctx.accountDataCopy.allowMilvaEuroFins)
          ? (_openBlock(), _createBlock(_component_Toggle, {
              key: 0,
              name: "billPerRequisition",
              label: "Fakturer pr rekvisition",
              data: _ctx.accountDataCopy.billPerRequisition,
              "onUpdate:data": _cache[2] || (_cache[2] = ($event: any) => (_ctx.accountDataCopy.billPerRequisition = $event))
            }, null, 8, ["data"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Select, {
          name: "primaryLabID",
          label: "Primary laboratory",
          required: "",
          options: _ctx.labOptions,
          data: _ctx.accountDataCopy.primaryLabID,
          "onUpdate:data": _cache[3] || (_cache[3] = ($event: any) => (_ctx.accountDataCopy.primaryLabID = $event))
        }, null, 8, ["options", "data"]),
        _createVNode(_component_Select, {
          name: "analysisDeliveryTimeOptionID",
          label: "Analysis delivery time",
          required: "",
          options: _ctx.analysisDeliveryTimeOptions,
          data: _ctx.accountDataCopy.analysisDeliveryTimeOptionID,
          "onUpdate:data": _cache[4] || (_cache[4] = ($event: any) => (_ctx.accountDataCopy.analysisDeliveryTimeOptionID = $event))
        }, null, 8, ["options", "data"]),
        _createVNode(_component_InputField, {
          name: "euroFinsNumber",
          label: "EuroFins Number",
          data: _ctx.accountDataCopy.euroFinsNumber,
          "onUpdate:data": _cache[5] || (_cache[5] = ($event: any) => (_ctx.accountDataCopy.euroFinsNumber = $event)),
          required: _ctx.accountDataCopy.primaryLabID === 'euroFins'
        }, null, 8, ["data", "required"]),
        _createVNode(_component_InputField, {
          name: "hojvangNumber",
          label: "Højvang Number",
          data: _ctx.accountDataCopy.hojvangNumber,
          "onUpdate:data": _cache[6] || (_cache[6] = ($event: any) => (_ctx.accountDataCopy.hojvangNumber = $event)),
          required: _ctx.accountDataCopy.primaryLabID === 'hojvang'
        }, null, 8, ["data", "required"]),
        _createVNode(_component_InputField, {
          name: "analysisReportEmail",
          label: "Email til modtagelse af analyserapporter",
          data: _ctx.accountDataCopy.analysisReportEmail,
          "onUpdate:data": _cache[7] || (_cache[7] = ($event: any) => (_ctx.accountDataCopy.analysisReportEmail = $event))
        }, null, 8, ["data"]),
        _renderSlot(_ctx.$slots, "default")
      ], 544))
    : _createCommentVNode("", true)
}