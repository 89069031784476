import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toggle = _resolveComponent("Toggle")
  const _component_InputField = _resolveComponent("InputField")

  return (_ctx.accountDataCopy)
    ? (_openBlock(), _createBlock("form", {
        key: 0,
        onSubmit: _cache[12] || (_cache[12] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"])),
        ref: "form"
      }, [
        _createVNode(_component_Toggle, {
          name: "isInvoicePaymentAllowed",
          label: _ctx.mixWB('W: Må gerne benytte fakturering'),
          data: _ctx.accountDataCopy.isInvoicePaymentAllowed,
          "onUpdate:data": _cache[1] || (_cache[1] = ($event: any) => (_ctx.accountDataCopy.isInvoicePaymentAllowed = $event))
        }, null, 8, ["label", "data"]),
        (_ctx.accountDataCopy.isInvoicePaymentAllowed)
          ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_Toggle, {
                name: "isInvoicePaymentSelected",
                label: _ctx.mixWB('W: Anvend fakturering som betalingsmetode'),
                data: _ctx.accountDataCopy.isInvoicePaymentSelected,
                "onUpdate:data": _cache[2] || (_cache[2] = ($event: any) => (_ctx.accountDataCopy.isInvoicePaymentSelected = $event))
              }, null, 8, ["label", "data"]),
              _createVNode(_component_InputField, {
                name: "fullName",
                label: _ctx.mixWB('W: Fulde navn'),
                data: _ctx.accountDataCopy.billingInfo.fullName,
                "onUpdate:data": _cache[3] || (_cache[3] = ($event: any) => (_ctx.accountDataCopy.billingInfo.fullName = $event)),
                required: _ctx.accountDataCopy.isInvoicePaymentSelected
              }, null, 8, ["label", "data", "required"]),
              _createVNode(_component_InputField, {
                name: "email",
                label: _ctx.mixWB('W: Email'),
                data: _ctx.accountDataCopy.billingInfo.email,
                "onUpdate:data": _cache[4] || (_cache[4] = ($event: any) => (_ctx.accountDataCopy.billingInfo.email = $event)),
                required: _ctx.accountDataCopy.isInvoicePaymentSelected
              }, null, 8, ["label", "data", "required"]),
              _createVNode(_component_InputField, {
                name: "phone",
                label: _ctx.mixWB('W: Telfonnummer'),
                data: _ctx.accountDataCopy.billingInfo.phone,
                "onUpdate:data": _cache[5] || (_cache[5] = ($event: any) => (_ctx.accountDataCopy.billingInfo.phone = $event)),
                required: _ctx.accountDataCopy.isInvoicePaymentSelected
              }, null, 8, ["label", "data", "required"]),
              _createVNode(_component_InputField, {
                name: "address",
                label: _ctx.mixWB('W: Adresse'),
                data: _ctx.accountDataCopy.billingInfo.address,
                "onUpdate:data": _cache[6] || (_cache[6] = ($event: any) => (_ctx.accountDataCopy.billingInfo.address = $event)),
                required: _ctx.accountDataCopy.isInvoicePaymentSelected
              }, null, 8, ["label", "data", "required"]),
              _createVNode(_component_InputField, {
                name: "postalCode",
                label: _ctx.mixWB('W: Postnummer'),
                data: _ctx.accountDataCopy.billingInfo.postalCode,
                "onUpdate:data": _cache[7] || (_cache[7] = ($event: any) => (_ctx.accountDataCopy.billingInfo.postalCode = $event)),
                required: _ctx.accountDataCopy.isInvoicePaymentSelected
              }, null, 8, ["label", "data", "required"]),
              _createVNode(_component_InputField, {
                name: "city",
                label: _ctx.mixWB('W: By'),
                data: _ctx.accountDataCopy.billingInfo.city,
                "onUpdate:data": _cache[8] || (_cache[8] = ($event: any) => (_ctx.accountDataCopy.billingInfo.city = $event)),
                required: _ctx.accountDataCopy.isInvoicePaymentSelected
              }, null, 8, ["label", "data", "required"]),
              _createVNode(_component_InputField, {
                name: "company",
                label: _ctx.mixWB('W: Virksomhed'),
                data: _ctx.accountDataCopy.billingInfo.company,
                "onUpdate:data": _cache[9] || (_cache[9] = ($event: any) => (_ctx.accountDataCopy.billingInfo.company = $event)),
                required: _ctx.accountDataCopy.isInvoicePaymentSelected
              }, null, 8, ["label", "data", "required"]),
              _createVNode(_component_InputField, {
                name: "vatNumber",
                label: _ctx.mixWB('W: CVR-nummer'),
                data: _ctx.accountDataCopy.billingInfo.vatNumber,
                "onUpdate:data": _cache[10] || (_cache[10] = ($event: any) => (_ctx.accountDataCopy.billingInfo.vatNumber = $event)),
                required: _ctx.accountDataCopy.isInvoicePaymentSelected
              }, null, 8, ["label", "data", "required"]),
              _createVNode(_component_InputField, {
                name: "att",
                label: _ctx.mixWB('W: Att.'),
                data: _ctx.accountDataCopy.billingInfo.att,
                "onUpdate:data": _cache[11] || (_cache[11] = ($event: any) => (_ctx.accountDataCopy.billingInfo.att = $event))
              }, null, 8, ["label", "data"])
            ], 64))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default")
      ], 544))
    : _createCommentVNode("", true)
}