
import { useStore } from 'vuex'
import {
  computed, ref, defineComponent,
  PropType,
  watch,
} from 'vue'
import Toggle from '@/components/Form/Toggle.vue'
import { IAccount } from '@/types'
import { mixWB } from '@/globals/javascript/utils/mixins'
import Divider from '@/components/Form/Divider.vue'
import { IProjectOptionV3, IProjectTypeV3 } from '@/types/resources-version-3'
import { copyObject } from '@/globals/javascript/utils/helpers'

type PartialAccount = Omit<
IAccount,
'numberID'|'nextQRLabelNumber'|'createdDate'|'nextScreeningNumber'
>

export default defineComponent({
  props: {
    accountData: {
      type: Object as PropType<PartialAccount | IAccount>,
      required: true,
    },
  },
  components: {
    Toggle,
    Divider,
  },
  setup(props, { emit }) {
    const store = useStore()
    const { isAdmin } = store.getters
    const accountDataCopy = ref<PartialAccount | IAccount>(copyObject(props.accountData))
    watch(accountDataCopy, () => {
      emit('fieldInput', accountDataCopy.value)
    }, {
      deep: true,
    })

    const onProjectTypeUpdate = (projectTypeID: string) => {
      if (!accountDataCopy.value.allowedProjectTypeIDs) {
        return
      }

      const index = accountDataCopy.value.allowedProjectTypeIDs.findIndex(
        (x) => x === projectTypeID,
      )

      if (index > -1) {
        accountDataCopy.value.allowedProjectTypeIDs.splice(index, 1)
      }
      else {
        accountDataCopy.value.allowedProjectTypeIDs.push(projectTypeID)
      }
    }

    const onProjectOptionUpdate = (projectOptionID: string) => {
      if (!accountDataCopy.value.autoSelectedProjectOptionIDs) {
        return
      }

      const index = accountDataCopy.value.autoSelectedProjectOptionIDs.findIndex(
        (x) => x === projectOptionID,
      )

      if (index > -1) {
        accountDataCopy.value.autoSelectedProjectOptionIDs.splice(index, 1)
      }
      else {
        accountDataCopy.value.autoSelectedProjectOptionIDs.push(projectOptionID)
      }
    }

    const onSubmit = () => {
      emit('onSubmit', accountDataCopy.value)
    }

    return {
      isAdmin,
      projectTypes: computed(() => store.getters.projectTypesAsArrayV3.map(
        (projectType: IProjectTypeV3) => ({
          id: projectType.id,
          title: mixWB(projectType.translation),
          isSelected: !!accountDataCopy.value.allowedProjectTypeIDs.includes(projectType.id),
          isActive: projectType.options.isActive,
        }),
      )),
      projectOptions: computed(() => store.getters.projectOptionsAsArrayV3.map(
        (projectOption: IProjectOptionV3) => ({
          id: projectOption.id,
          title: mixWB(projectOption.translation),
          isSelected: !!accountDataCopy.value.autoSelectedProjectOptionIDs.includes(
            projectOption.id,
          ),
          isActive: projectOption.options.isActive,
        }),
      )),
      onProjectTypeUpdate,
      onProjectOptionUpdate,
      onSubmit,
      accountDataCopy,
    }
  },
})

