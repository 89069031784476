
import { computed, defineComponent } from 'vue'
import { IStat } from '@/types'

export default defineComponent({
  props: {
    stats: {
      type: Object,
      required: true,
    },
    monthsToShow: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  setup(props) {
    return {
      monthStats: computed(() => {
        if (!props.stats.monthlyStats) {
          return {}
        }

        let monthYears = Object.keys(props.stats.monthlyStats).sort().reverse()

        if (props.monthsToShow === 0) {
          return {}
        }

        if (props.monthsToShow > 0) {
          monthYears = monthYears.slice(0, props.monthsToShow)
        }

        return monthYears.reduce((carry: {[monthYear: string]: IStat}, monthYear) => {
          carry[monthYear] = props.stats.monthlyStats[monthYear]
          return carry
        }, {})
      }),
    }
  },
})
