
import { defineComponent, PropType } from 'vue'
import DialogTitle from '@/components/Modals/DialogTitle.vue'
import InputField from '@/components/Form/InputField.vue'
import Button from '@/components/Form/Button.vue'
import { IAccount } from '@/types'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'ProAccountPickUpPoint',
  emits: ['close-dialog', 'pass-to-parent'],
  props: {
    pickUpPointToEdit: {
      type: Object as PropType<IAccount['pickUpPoints'][number]>,
      required: false,
    },
  },

  data() {
    return {
      pickUpPoint: {
        placement: this.pickUpPointToEdit?.placement ?? '',
        address: {
          address: this.pickUpPointToEdit?.address?.address ?? '',
          postalCode: this.pickUpPointToEdit?.address?.postalCode ?? '',
          city: this.pickUpPointToEdit?.address?.city ?? '',
        },
      },
    }
  },
  computed: {
    ...mapGetters([
      'accounts',
    ]),
    isEditingExisting(): boolean {
      return !!this.pickUpPointToEdit
    },
  },
  methods: {
    onAddNew() {
      this.$emit('pass-to-parent', {
        name: 'add-new',
        value: this.pickUpPoint,
      })
      this.onClose()
      return
    },
    onUpdate() {
      this.$emit('pass-to-parent', {
        name: 'update',
        value: this.pickUpPoint,
      })
      this.onClose()
      return
    },
    onDelete() {
      const answer = window.confirm(this.mixWB('W: Er du sikker på et su vil slette dette pick up point?'))
      if (!answer) {
        return
      }

      this.$emit('pass-to-parent', {
        name: 'delete',
        value: this.pickUpPoint,
      })
      this.onClose()
      return
    },
    onSubmit() {
      if (this.isEditingExisting) {
        this.onUpdate()
        return
      }
      this.onAddNew()
    },
    onClose() {
      this.$emit('close-dialog')
    },
  },
  components: {
    DialogTitle,
    InputField,
    Button,
  },

})
