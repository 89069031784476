
import { useStore } from 'vuex'
import {
  ref, defineComponent,
  PropType,
  watch,
  computed,
} from 'vue'
import {
  IAccount,
} from '@/types'
import { mixWB } from '@/globals/javascript/utils/mixins'
import Divider from '@/components/Form/Divider.vue'
import InputField from '@/components/Form/InputField.vue'
import { IAnalysisDeliveryTimeOptionV3, ISampleTypeV3 } from '@/types/resources-version-3'
import ChangeLogV3 from '@/components/ChangeLogV3.vue'
import Toggle from '@/components/Form/Toggle.vue'
import { copyObject, updateLog } from '@/globals/javascript/utils/helpers'
import { IPricesProItem } from '@/types/prices'
import { useRoute } from 'vue-router'

type PartialAccount = Omit<IAccount, 'numberID'|'nextQRLabelNumber'|'createdDate'|'nextScreeningNumber'>

export default defineComponent({
  props: {
    accountData: {
      type: Object as PropType<PartialAccount | IAccount>,
      required: true,
    },
  },
  components: {
    Divider,
    InputField,
    ChangeLogV3,
    Toggle,
  },
  setup(props, { emit }) {
    const store = useStore()
    const route = useRoute()
    const {
      isAdmin, analysisDeliveryTimeOptionsAsArrayV3, sampleTypesAsArrayV3, pricesProAsArray,
    } = store.getters
    const areLogsShowing = ref(false)
    const successMessage = ref(false)
    const accountDataCopy = ref<PartialAccount | IAccount>(copyObject(props.accountData))
    const isNew = computed(() => route.name === 'ProAccountNew')

    function getDefaultValue(key: string, id: string | number) {
      const samplerPlusPrices = (
        pricesProAsArray as IPricesProItem[]).find((p) => p.id === 'samplerPlus')
      const pricesItem = samplerPlusPrices
      && samplerPlusPrices[key as keyof typeof samplerPlusPrices]
      if (!pricesItem || typeof pricesItem === 'string' || !pricesItem[id as keyof typeof pricesItem]) {
        return '-'
      }
      const priceItem = pricesItem[id as keyof typeof pricesItem]
      let text = ''

      if (priceItem.value !== undefined) {
        text += '( '
        text += mixWB('W: Standard')
        text += ': '
        text += priceItem.type === 'multiplier' ? priceItem.value * 100 : priceItem.value
        text += priceItem.type === 'multiplier' ? '%' : ' kr.'
        text += ' )'
      }
      return text
    }

    // Sort objects
    Object.keys(accountDataCopy.value.prices).forEach((key) => {
      const item = accountDataCopy.value.prices[key as keyof typeof accountDataCopy.value.prices]

      // Create a sorted version of the item
      const itemOrdered = Object.keys(item)
        .sort()
        .reduce((prev, currentKey) => {
          prev[currentKey] = item[currentKey as keyof typeof item]
          return prev
        }, {} as typeof item)

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      accountDataCopy.value.prices[key as keyof typeof accountDataCopy.value.prices] = itemOrdered
    })
    watch(accountDataCopy, () => {
      emit('fieldInput', accountDataCopy.value)
    }, {
      deep: true,
    })
    const getAnalysisDeliveryTimeTitle = (id: string | number) => {
      const deliveryItem: IAnalysisDeliveryTimeOptionV3 = analysisDeliveryTimeOptionsAsArrayV3
        .find((a: IAnalysisDeliveryTimeOptionV3) => a.id === id)

      let title = ''
      if (deliveryItem.days === 1) {
        title = mixWB('1_DAY_X_O_CLOCK', [String(deliveryItem.hours)])
      }
      else {
        title = mixWB('X_DAYS_X_O_CLOCK', [String(deliveryItem.days), String(deliveryItem.hours)])
      }
      return title
    }
    const getMatAnalysesTitle = (id: string | number) => {
      const sampleTypeItem: ISampleTypeV3 = sampleTypesAsArrayV3
        .find((a: ISampleTypeV3) => a.id === id)

      return mixWB(sampleTypeItem.translation)
    }
    const getSampleHandOverTitle = (id: string | number) => {
      const titleMapper: {[key:string]: string} = {
        'drop-off-point': mixWB('W: Droppoint'),
        'custom-address': mixWB('W: Egen adresse (gratis ved bestilling af over 5 analyser)'),
        'pick-up-point': mixWB('W: Pickup point'),
      }

      return titleMapper[id]
    }

    const onSubmit = () => {
      updateLog({
        newItem: accountDataCopy.value.prices.other.projectFee,
        oldItem: props.accountData.prices.other.projectFee,
        isNew: false,
      })

      Object.entries(accountDataCopy.value.prices.analysisDeliveryTime).forEach(([key, item]) => {
        const newItem = item
        const oldItem = props.accountData.prices.analysisDeliveryTime[key]
        updateLog({
          newItem,
          oldItem,
          isNew: false,
        })
      })

      Object.entries(accountDataCopy.value.prices.matAnalyses).forEach(([key, item]) => {
        const newItem = item
        const oldItem = props.accountData.prices.matAnalyses[key]
        updateLog({
          newItem,
          oldItem,
          isNew: false,
        })
      })
      Object.entries(accountDataCopy.value.prices.sampleHandOver).forEach(([key, item]) => {
        const newItem = item
        const oldItem = props.accountData.prices.sampleHandOver[key]
        updateLog({
          newItem,
          oldItem,
          isNew: false,
        })
      })

      emit('onSubmit', accountDataCopy.value)
    }

    return {
      isNew,
      isAdmin,
      areLogsShowing,
      onSubmit,
      successMessage,
      getAnalysisDeliveryTimeTitle,
      getMatAnalysesTitle,
      getSampleHandOverTitle,
      accountDataCopy,
      getDefaultValue,
    }
  },
})

