import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputField = _resolveComponent("InputField")
  const _component_Select = _resolveComponent("Select")
  const _component_ImageUpload = _resolveComponent("ImageUpload")

  return (_ctx.accountDataCopy)
    ? (_openBlock(), _createBlock("form", {
        key: 0,
        onSubmit: _cache[12] || (_cache[12] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"])),
        ref: "form"
      }, [
        _createVNode(_component_InputField, {
          name: "name",
          label: "Navn",
          data: _ctx.accountDataCopy.name,
          "onUpdate:data": _cache[1] || (_cache[1] = ($event: any) => (_ctx.accountDataCopy.name = $event)),
          required: ""
        }, null, 8, ["data"]),
        _createVNode(_component_InputField, {
          name: "companyInitials",
          label: "Virksomhedens initialer (brugt til sagsnummer)",
          data: _ctx.accountDataCopy.companyInitials,
          "onUpdate:data": _cache[2] || (_cache[2] = ($event: any) => (_ctx.accountDataCopy.companyInitials = $event)),
          required: ""
        }, null, 8, ["data"]),
        _createVNode(_component_InputField, {
          name: "address",
          label: "Adresse",
          data: _ctx.accountDataCopy.address,
          "onUpdate:data": _cache[3] || (_cache[3] = ($event: any) => (_ctx.accountDataCopy.address = $event)),
          required: ""
        }, null, 8, ["data"]),
        _createVNode(_component_InputField, {
          name: "postalCode",
          label: "Postnummer",
          data: _ctx.accountDataCopy.postalCode,
          "onUpdate:data": _cache[4] || (_cache[4] = ($event: any) => (_ctx.accountDataCopy.postalCode = $event)),
          required: ""
        }, null, 8, ["data"]),
        _createVNode(_component_InputField, {
          name: "city",
          label: "By",
          data: _ctx.accountDataCopy.city,
          "onUpdate:data": _cache[5] || (_cache[5] = ($event: any) => (_ctx.accountDataCopy.city = $event)),
          required: ""
        }, null, 8, ["data"]),
        _createVNode(_component_Select, {
          name: "baseCountry",
          label: "Land",
          options: _ctx.countryOptions,
          data: _ctx.accountDataCopy.baseCountry,
          "onUpdate:data": _cache[6] || (_cache[6] = ($event: any) => (_ctx.accountDataCopy.baseCountry = $event))
        }, null, 8, ["options", "data"]),
        _createVNode(_component_InputField, {
          name: "contactPerson.name",
          label: "Kontaktperson - Navn",
          data: _ctx.accountDataCopy.contactPerson.name,
          "onUpdate:data": _cache[7] || (_cache[7] = ($event: any) => (_ctx.accountDataCopy.contactPerson.name = $event)),
          required: ""
        }, null, 8, ["data"]),
        _createVNode(_component_InputField, {
          name: "contactPerson.phoneNumber",
          label: "Kontaktperson - Telefon",
          data: _ctx.accountDataCopy.contactPerson.phoneNumber,
          "onUpdate:data": _cache[8] || (_cache[8] = ($event: any) => (_ctx.accountDataCopy.contactPerson.phoneNumber = $event)),
          required: ""
        }, null, 8, ["data"]),
        _createVNode(_component_InputField, {
          name: "contactPerson.email",
          label: "Kontaktperson - Email",
          data: _ctx.accountDataCopy.contactPerson.email,
          "onUpdate:data": _cache[9] || (_cache[9] = ($event: any) => (_ctx.accountDataCopy.contactPerson.email = $event)),
          required: ""
        }, null, 8, ["data"]),
        _createVNode(_component_ImageUpload, {
          disabled: !_ctx.accountDataCopy.id,
          label: "Main logo",
          storagePath: `Logos/${ _ctx.accountDataCopy.id }`,
          mimeType: "image/png",
          data: _ctx.accountDataCopy.images.mainLogo,
          "onUpdate:data": _cache[10] || (_cache[10] = ($event: any) => (_ctx.accountDataCopy.images.mainLogo = $event)),
          name: "mainLogo"
        }, null, 8, ["disabled", "storagePath", "data"]),
        _createVNode(_component_ImageUpload, {
          disabled: !_ctx.accountDataCopy.id,
          label: "Email logo",
          storagePath: `Logos/${ _ctx.accountDataCopy.id }`,
          mimeType: "image/png",
          data: _ctx.accountDataCopy.images.emailLogo,
          "onUpdate:data": _cache[11] || (_cache[11] = ($event: any) => (_ctx.accountDataCopy.images.emailLogo = $event)),
          name: "emailLogo"
        }, null, 8, ["disabled", "storagePath", "data"]),
        _renderSlot(_ctx.$slots, "default")
      ], 544))
    : _createCommentVNode("", true)
}