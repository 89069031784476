
import { useStore } from 'vuex'
import {
  ref, defineComponent,
  PropType,
  watch,
} from 'vue'
import InputField from '@/components/Form/InputField.vue'
import { IAccount } from '@/types'
import { copyObject } from '@/globals/javascript/utils/helpers'
import Toggle from '@/components/Form/Toggle.vue'

type PartialAccount = Omit<
IAccount,
'numberID'|'nextQRLabelNumber'|'createdDate'|'nextScreeningNumber'
>

export default defineComponent({
  props: {
    accountData: {
      type: Object as PropType<PartialAccount | IAccount>,
      required: true,
    },
  },
  components: {
    Toggle, InputField,
  },
  setup(props, { emit }) {
    const accountDataCopy = ref<PartialAccount | IAccount>(copyObject(props.accountData))
    watch(accountDataCopy, () => {
      emit('fieldInput', accountDataCopy.value)
    }, {
      deep: true,
    })

    const onSubmit = () => {
      emit('onSubmit', accountDataCopy.value)
    }

    return {
      onSubmit,
      accountDataCopy,
      Toggle,
    }
  },
})

